import React, { Component } from 'react'
import { Sidebar, Menu, Container, Image, Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Media } from './Media'

export class MobileContainer extends Component {
    state = {
        sidebarOpened: false
    }

    handleSidebarHide = () => this.setState({ sidebarOpened: false })

    handleToggle = () => this.setState({ sidebarOpened: true })

    render() {
        const { children } = this.props
        const { sidebarOpened } = this.state

        return (
            <Media as={Sidebar.Pushable} at='mobile'>
                <Sidebar.Pushable>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        inverted
                        onHide={this.handleSidebarHide}
                        vertical
                        visible={sidebarOpened}
                    >
                        <Menu.Item as='a' active>
                            Arena-Games
                        </Menu.Item>
                        <Menu.Item as='a' href='https://arena-game.app'>Main site</Menu.Item>
                        <Menu.Item as="a" href="https://boards.arena-game.app">
                          {(navigator.language.startsWith('pt')) ? "Fórum" : "Boards"}
                        </Menu.Item>
                        <Menu.Item
                          as={Link}
                          to="/patreon"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {(navigator.language.startsWith('pt')) ? "Apoie no Patreon" : "Suport us on Patreon"}
                        </Menu.Item>
                        <Menu.Item
                          as={Link}
                          to="/discord"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Discord
                        </Menu.Item>
                        <Menu.Item href="https://cc.arena-game.app" target="_blank">
                          {(navigator.language.startsWith('pt')) ? "Criador de Personagem" : "Character Creator"}
                        </Menu.Item>
                        <Menu.Item href="https://naruto.arena-game.app" target='_blank'>
                          Naruto Arena
                        </Menu.Item>
                    </Sidebar>
                    <Sidebar.Pusher dimmed={sidebarOpened}>
                            <Container>
                                <Menu secondary inverted style={{ backgroundColor: "rgba(23, 30, 34, 0.5)" }}>
                                    <Menu.Item onClick={this.handleToggle}>
                                        <Icon name='sidebar' />
                                    </Menu.Item>
                                    <Menu.Item as={Link} to="/">
                                        <Image src="/assets/logo.png" size="tiny" />
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Button as={Link} to="/download" color="teal" floated="right">Play now</Button>
                                    </Menu.Item>
                                </Menu>
                            </Container>
                        {children}
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Media>
        )
    }
}
