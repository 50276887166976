import React, { ReactChild, ReactChildren } from 'react'
import { Image, Menu, Container, Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Media } from './Media'

interface MobileContainerProps {
    children: ReactChild | ReactChildren;
}

export const DesktopContainer = ({ children } : MobileContainerProps) => (
    <Media greaterThan='mobile'>
        <Menu secondary inverted style={{ backgroundColor: "rgba(23, 30, 34, 0.5)" }}>
            <Container>
                <Menu.Item as={Link} to="/">
                    <Image src="/assets/logo.png" size="tiny" />
                </Menu.Item>
                <Menu.Item href="https://arena-game.app" target='_blank'>Arena-Games</Menu.Item>
                <Menu.Item href="https://boards.arena-game.app" target='_blank'>{(navigator.language.startsWith('pt')) ? "Fórum" : "Boards"}</Menu.Item>
                <Menu.Item href="https://cc.arena-game.app" target='_blank'>{(navigator.language.startsWith('pt')) ? "Criador de Personagem" : "Character Creator"}</Menu.Item>
                <Menu.Item href="https://naruto.arena-game.app" target='_blank'>Naruto Arena</Menu.Item>
            </Container>
            <Menu.Item>
                <div>
                    <Button circular color='grey' as={Link} to="/discord" target="_blank" rel="noopener noreferrer" icon='discord' />
                    <Button circular color='orange' as={Link} to="/patreon"> <Icon name="patreon"/>{(navigator.language.startsWith('pt')) ? "Apoie-nos no Patreon" : "Support us on Patreon"}</Button>
                </div>
            </Menu.Item>
            <Menu.Item>
                <Button as={Link} to="/download" color="teal" floated="right">{(navigator.language.startsWith('pt')) ? "Jogue Agora" : "Play Now"}</Button>
            </Menu.Item>
        </Menu>
        {children}
    </Media>
)
